import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from "@/router";
import {perPageOptions} from "@core/utils/filter";
import Vue from "vue";

export default function useList() {
    const toast = useToast()
    const refListTable = ref(null)

    const tableColumns = [
        {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
        {key: 'selection', label: 'SELECT', thStyle: {width: "1%"}},
        {key: 'image_url', label: 'PROFILE', sortable: false, thStyle: {width: "1%"}},
        {key: 'name', label: 'NAME', sortable: true},
        {key: 'lastname', label: 'LASTNAME', sortable: true},
        {key: 'gender', label: 'GENDER', sortable: false},
        {key: 'email', label: 'EMAIL', sortable: true},
        {key: 'phone', label: 'PHONE', sortable: true},
        {key: 'status', label: 'STATUS', sortable: true, thStyle: {width: "1%"}},
        {key: 'actions', label: 'ACTIONS', sortable: false, thStyle: {width: "2%"}},
    ]

    const busy = ref(false)
    const perPage = ref(10)
    const totalCount = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilterId = ref(1)
    const memberTypeFilterId = ref(0)
    const memberData = ref([])

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCount.value,
        }
    })

    const reFetchData = () => {
        refListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilterId, memberTypeFilterId], () => {
        reFetchData()
    })

    const fetchList = (ctx, callback) => {
        busy.value = true
        store.dispatch('store/fetchItems', {
            search: searchQuery.value,
            start: currentPage.value,
            length: perPage.value,
            sortColumn: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status_filter: statusFilterId.value,
            type_filter: memberTypeFilterId.value,
        }).then(response => {
            memberData.value = response.data.data
            callback(response.data.data)
            totalCount.value = response.data.total_count
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            if (error.response.status === 403) {
                router.push({name: 'home'})
            }
        }).finally(() => {
            busy.value = false
        })
    }

    const changeSelectValue = (target) => {
        memberData.value.forEach(function callback(value, index) {
            if (value.id === target.id) {
                value.selection = !target.selection
            }
        })
    }

    const removeSelectedRecords = () => {
        Vue.swal({
            title: 'Delete?',
            text: 'Selected record is deleted!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                let idList = [];
                memberData.value.forEach(function callback(value, index) {
                    if (value.selection === true) {
                        idList.push(value.id)
                    }
                })

                if (idList.length === 0) {
                    Vue.swal({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Please select record.',
                        confirmButtonText: 'Ok',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                    })

                    return
                }

                store.dispatch('store/removeMemberList', {dataInfo: idList}).then(response => {
                    reFetchData()
                }).catch(error => {
                    Vue.swal({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.message,
                        confirmButtonText: 'Ok',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                    })
                })
            }
        })
    }

    return {
        tableColumns,
        perPage,
        currentPage,
        totalCount,
        dataMeta,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        busy,
        statusFilterId,
        memberTypeFilterId,

        perPageOptions,

        fetchList,
        reFetchData,
        changeSelectValue,
        removeSelectedRecords,
    }
}
